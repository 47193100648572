// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
import { I18n as I18nClass } from 'i18n-js';
import translations from "../locales/de.json";
console.log("🚀 ~ translations:", translations)

// require("@rails/ujs")
Rails.start()
// require("turbolinks").start() //TODO:Enable turbolinks and make react parts work
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("jquery")
require("chartkick")
require("chart.js")
// require("select2")

import PDFObject from 'pdfobject'

const feather = require('feather-icons')
import PerfectScrollbar from 'perfect-scrollbar';

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'

// I18n.locale = 'de';

// I18n.translations = translations
const i18nInstance= new I18nClass(translations, {defaultLocale: "de"});
i18nInstance.locale = "de";
window.I18n = i18nInstance

require('datatables.net');
require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-responsive-dt');

// const jQuery = jquery;

// $(document).on("turbolinks:load", () => {
$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    feather.replace()

    // $('#user_main_address_attributes_pincode').on('blur', function (e) {
    //     $.get('/api/addresses/geography/pincode/' + this.value).then((response) => {
    //         console.log('response', response)
    //         if (response.id) {
    //             $('#user_main_address_attributes_city').val(response.city)
    //             $('#user_main_address_attributes_state').val(response.state)
    //             $('#user_main_address_attributes_country').val(response.country)
    //         }
    //     })
    // })
    // $('.dataTables_length select').select2({ minimumResultsForSearch: Infinity });
    // GDPR PAGE SCRIPTS
    $('.terms-check').on('change', function () {
        if ($(this).prop("checked") == true) {
            $(this).prop("checked", false);
            $('#termsModal').modal('show')
            PDFObject.embed("/documents/2023_Datenschutzschulung_Ehrenamtliche_SP.pdf", "#terms-pdf");
        }

    })
    $('.terms-save').on('click', function () {
        // $('.terms-check').prop("checked", true);
        $('#termsModal').modal('hide')
        $('#termsModal2').modal('show')
        PDFObject.embed("/documents/2023_Verpflichtungserklärung_Ehrenamtliche_EAP.pdf", "#terms-pdf2");
    })

    $('.terms-save2').on('click', function () {
      $('.terms-check').prop("checked", true);
      $('#termsModal2').modal('hide')
    })

    $('#termsCheck2').on('change', function () {
      console.log('clicked')
      if ($(this).prop("checked") == true) {
        $('#terms-save2').removeClass('disabled')
        $('#terms-save2').removeAttr('disabled')
      }else{
        $('#terms-save2').addClass('disabled')
        $('#terms-save2').attr('disabled', true)
      }
    })
    

    $('.third-party-check').on('change', function () {
      if ($(this).prop("checked") == true) {
          $(this).prop("checked", false);
          $('#thirdPartyServicesModal').modal('show')
      }
    })

    $('.third-party-save').on('click', function () {
        $('.third-party-check').prop("checked", true);
        $('#thirdPartyServicesModal').modal('hide')
    })

    ////////// NAVBAR //////////

    // Initialize PerfectScrollbar of navbar menu for mobile only
    let psNavbar = null
    if (window.matchMedia('(max-width: 991px)').matches && $('#navbarMenu').length) {
        psNavbar = new PerfectScrollbar('#navbarMenu', {
            suppressScrollX: true
        });
    } else {
        // psNavbar = 
        // console.log('desktop')
    }

    // Showing sub-menu of active menu on navbar when mobile
    function showNavbarActiveSub() {
        if (window.matchMedia('(max-width: 991px)').matches && $('#navbarMenu').length) {
            $('#navbarMenu .active').addClass('show');
        } else {
            $('#navbarMenu .active').removeClass('show');
        }
    }

    showNavbarActiveSub()
    $(window).resize(function () {
        showNavbarActiveSub()
    })

    // Initialize backdrop for overlay purpose
    $('body').append('<div class="backdrop"></div>');


    // Showing sub menu of navbar menu while hiding other siblings
    $('.navbar-menu .with-sub .nav-link').on('click', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('show');
        $(this).parent().siblings().removeClass('show');

        if (window.matchMedia('(max-width: 991px)').matches) {
            psNavbar.update();
        }
    })

    // Closing dropdown menu of navbar menu
    $(document).on('click touchstart', function (e) {
        e.stopPropagation();

        // closing nav sub menu of header when clicking outside of it
        if (window.matchMedia('(min-width: 992px)').matches) {
            var navTarg = $(e.target).closest('.navbar-menu .nav-item').length;
            if (!navTarg) {
                $('.navbar-header .show').removeClass('show');
            }
        }
    })

    $('#mainMenuClose').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('navbar-nav-show');
    });

    $('#sidebarMenuOpen').on('click', function (e) {
        e.preventDefault();
        $('body').addClass('sidebar-show');
    })

    // Navbar Search
    $('#navbarSearch').on('click', function (e) {
        e.preventDefault();
        $('.navbar-search').addClass('visible');
        $('.backdrop').addClass('show');
    })

    $('#navbarSearchClose').on('click', function (e) {
        e.preventDefault();
        $('.navbar-search').removeClass('visible');
        $('.backdrop').removeClass('show');
    })



    ////////// SIDEBAR //////////

    // Initialize PerfectScrollbar for sidebar menu
    if ($('#sidebarMenu').length) {
        const psSidebar = new PerfectScrollbar('#sidebarMenu', {
            suppressScrollX: true
        });


        // Showing sub menu in sidebar
        $('.sidebar-nav .with-sub').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('show');

            psSidebar.update();
        })
    }


    $('#mainMenuOpen').on('click touchstart', function (e) {
        e.preventDefault();
        $('body').addClass('navbar-nav-show');
    })

    $('#sidebarMenuClose').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('sidebar-show');
    })

    // hide sidebar when clicking outside of it
    $(document).on('click touchstart', function (e) {
        e.stopPropagation();

        // closing of sidebar menu when clicking outside of it
        if (!$(e.target).closest('.burger-menu').length) {
            var sb = $(e.target).closest('.sidebar').length;
            var nb = $(e.target).closest('.navbar-menu-wrapper').length;
            if (!sb && !nb) {
                if ($('body').hasClass('navbar-nav-show')) {
                    $('body').removeClass('navbar-nav-show');
                } else {
                    $('body').removeClass('sidebar-show');
                }
            }
        }
    });

    $('[data-toggle="tooltip"]').tooltip()

    const asideBody = new PerfectScrollbar('.aside-body', {
        suppressScrollX: true
    });

    if ($('.aside-backdrop').length === 0) {
        $('body').append('<div class="aside-backdrop"></div>');
    }

    var mql = window.matchMedia('(min-width:992px) and (max-width: 1199px)');

    function doMinimize(e) {
        if (e.matches) {
            $('.aside').addClass('minimize');
        } else {
            $('.aside').removeClass('minimize');
        }

        asideBody.update()
    }

    mql.addListener(doMinimize);
    doMinimize(mql);

    $('.aside-menu-link').on('click', function (e) {
        e.preventDefault()

        if (window.matchMedia('(min-width: 992px)').matches) {
            $(this).closest('.aside').toggleClass('minimize');
        } else {

            $('body').toggleClass('show-aside');
        }

        asideBody.update()
    })

    $('.nav-aside .with-sub').on('click', '.nav-link', function (e) {
        e.preventDefault();

        $(this).parent().siblings().removeClass('show');
        $(this).parent().toggleClass('show');

        asideBody.update()
    })

    $('body').on('mouseenter', '.minimize .aside-body', function (e) {
        // console.log('e');
        $(this).parent().addClass('maximize');
    })

    $('body').on('mouseleave', '.minimize .aside-body', function (e) {
        $(this).parent().removeClass('maximize');

        asideBody.update()
    })

    $('body').on('click', '.aside-backdrop', function (e) {
        $('body').removeClass('show-aside');
    })

    // $('.header-search').select2({
    // 	theme: 'bootstrap4',
    // })

    // $('.dates').daterangepicker({
    // 	locale: {
    //     format: 'DD/MM/YYYY'
    //   },
    //    "maxSpan": {
    //       "days": 31
    //   },
    // });


})

